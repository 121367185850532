<template>
  <section id="quick-replies-message" class="quick-replies-message">
    <form class="quick-replies-message__wrapper" @submit.prevent="sendMessage">
      <!-- <input
      v-for="(qr, index) in quickReplies"
      :key="index"
      v-model="quickReplies[index]"
      ref="reply"
      class="inputx"
      placeholder="Placeholder"
    /> -->
      <vs-input
        v-for="(qr, index) in quickReplies"
        :key="index"
        v-model="quickReplies[index]"
        ref="reply"
        class="inputx"
        :placeholder="
          `${
            lang.botMaker.botEditor.messageType.quickReply.title[
              languageSelected
            ]
          } ${index + 1}`
        "
      ></vs-input>
      <vs-button color="primary" type="border" @click="addReply">
        + Add new
      </vs-button>
    </form>

    <!-- <form class="wrapper" @submit.prevent="sendMessage">
    <div class="preview">
      <img id="preview-img" />
    </div>
    <div class="input">
      <label for="botMakerUploadImage">
        <feather-icon
          icon="PaperclipIcon"
          class="cursor-pointer button"
        ></feather-icon>
      </label>
      <input
        class="hidden"
        @change="handleImage($event)"
        id="botMakerUploadImage"
        type="file"
        accept="image/*"
        ref="file"
      />
      <vs-input
        disabled
        class="flex-1 input-message user-says"
        required
        :placeholder="
          lang.botMaker.botEditor.messageType.image.description[
            languageSelected
          ]
        "
        v-model="botMessageInEdit.type.name"
        :danger="hasErrors"
        :danger-text="
          lang.botMaker.botEditor.messageType.image.error[languageSelected]
        "
      />
    </div>
  </form> -->
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import EmojiPicker from 'vue-emoji-picker'

export default {
  // components: {
  //   EmojiPicker
  // },
  props: {
    hasErrors: Boolean
  },
  data() {
    return {
      quickReplies: [''],
      input: '',
      search: '',
      inputDisabled: false,
      buttonDisabled: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['botMessageInEdit'])
  },
  methods: {
    addReply() {
      this.quickReplies.push('')
      this.$nextTick(() => {
        let index = this.quickReplies.length - 1
        let input = this.$refs.reply[index]
        input.focus()
      })
    },
    previewImage(event) {
      const reader = new FileReader()
      reader.onload = function() {
        const output = document.getElementById('preview-img')
        output.src = reader.result
      }
      reader.readAsDataURL(event.target.files[0])
    },
    handleImage($event) {
      let file = $event.target.files[0]
      if (file) {
        this.previewImage($event)
        this.botMessageInEdit.type.fileUrl = null
        this.botMessageInEdit.type.setMessage($event.target.files[0])
      }
    }
  }
}
</script>

<style lang="scss">
.quick-replies-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;

  &__wrapper {
    max-width: 230px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    border-radius: 20px;
    padding: 15px;
    background-color: #f0f0f0;
    input {
      margin-bottom: 15px;
    }
  }
}

// .wrapper {
//   position: relative;
//   width: 100%;
//   display: flex !important;
//   flex-direction: column;

//   .input {
//     position: relative;
//     .input-message {
//       .vs-input--input.normal {
//         padding-left: 40px;
//       }
//     }
//   }

//   // .vs-con-input-label {
//   //   width: 100% !important;
//   // }
//   .preview {
//     display: flex;
//     justify-content: center;
//     margin-bottom: 20px;
//     #preview-img {
//       max-height: 150px;
//     }
//   }
// }

// .regular-input {
//   padding: 0.5rem 1rem;
//   border-radius: 3px;
//   border: 1px solid #ccc;
//   width: 20rem;
//   height: 12rem;
// }

// .emoji-invoker {
//   position: absolute;
//   width: 1.5rem;
//   height: 1.5rem;
//   margin: 7px 15px 7px 7px;
//   border-radius: 50%;
//   cursor: pointer;
//   transition: all 0.2s;
//   z-index: 10;
// }
// .emoji-invoker:hover {
//   transform: scale(1.1);
// }
// .emoji-invoker > svg {
//   fill: #b1c6d0;
// }

// .user-says.emoji-picker {
//   position: relative;
//   font-family: Montserrat;
//   border: 1px solid #ccc;
//   width: 15rem;
//   height: 20rem;
//   overflow: scroll;
//   padding: 1rem;
//   box-sizing: border-box;
//   border-radius: 0.5rem;
//   background: #fff;
//   box-shadow: 1px 1px 8px #c7dbe6;
// }
// .emoji-picker__search {
//   display: flex;
// }
// .emoji-picker__search > input {
//   flex: 1;
//   border-radius: 10rem;
//   border: 1px solid #ccc;
//   padding: 0.5rem 1rem;
//   outline: none;
// }
// .emoji-picker h5 {
//   margin-bottom: 0;
//   color: #b1b1b1;
//   text-transform: uppercase;
//   font-size: 0.8rem;
//   cursor: default;
// }
// .emoji-picker .emojis {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// }
// .emoji-picker .emojis:after {
//   content: '';
//   flex: auto;
// }
// .emoji-picker .emojis span {
//   padding: 0.2rem;
//   cursor: pointer;
//   border-radius: 5px;
// }
// .emoji-picker .emojis span:hover {
//   background: #ececec;
//   cursor: pointer;
// }

// .input-message {
//   width: 100%;
// }

// .user-says.input-message textarea,
// .user-says.input-message .input-span-placeholder {
//   padding-left: 40px !important;
// }

// .wrapper {
//   transition: 0.5s;
// }

// .icon-large .feather {
//   height: 40px !important;
//   width: 40px !important;
// }

// .icon-large .feather:hover {
//   transform: scale(1.1);
// }

// .bot-maker .button {
//   margin: 8px 0px 8px 8px;
//   position: absolute !important;
//   width: 20px !important;
//   left: 5px !important;
//   top: 0 !important;
//   color: #b1c6d0;
//   z-index: 10;
// }
</style>
